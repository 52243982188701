.divider {
    width: 100%;
    max-width: 1024px;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 28px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
}

.divider:before {
    content: "";
    border-bottom: 2px solid #3c3c3c;
    flex: 1 1;
    margin-right: 1em;
}

.divider:after {
    content: "";
    border-bottom: 2px solid #3c3c3c;
    flex: 1 1;
    margin-left: 1em;
}
