.Navbar.Wrapper {
    height: 80px;
    background-color: #242424;
    font-weight: 500;
    box-shadow: 0 4px 12px rgb(0 0 0 / 40%);
    font-size: 1.9rem;
    padding-left: 20px;
    padding-right: 20px;
    position: sticky;
    top: 0;
    color: white;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Times, Times New Roman, serif;
}

@media only screen and (max-width: 800px) {
    .scroll-up {
        transform: translateY(0);
        transition: all 0.3s ease-in-out;
    }
    .scroll-down {
        transform: translateY(-100%);
        transition: all 0.3s ease-in-out;
    }
}

.Navbar.Grid-Item:nth-child(2) {
    display: flex;
    align-items: center;
}
