#page-wrap {
    overflow: visible;
}

/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 30px;
    height: 24px;
    left: 28px;
    top: 28px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: var(--warm);
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: var(--hot);
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    background: var(--hot);
    border-radius: 50%;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    height: 100%;
}

/* General sidebar styles */
.bm-menu {
    background: #303030;
    padding: 1em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #303030;
    font-weight: 600;
}

/* Individual item */
.bm-item {
    display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}

.Sidebar.logo-container {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 200px;
    margin: 0 auto;
    border: 2px solid var(--warm);
    padding: 3%;
    background: var(--dark);
}

.Sidebar.title {
    font-size: 1.7em;
    color: lightgray;
}

.Sidebar.spacer {
    height: 8px;
}

.Button.container {
    background: var(--warm);
    text-align: center;
    padding: 0.7em;
    cursor: pointer;
    display: flex;
}

.Button.Left,
.Button.Right {
    flex: 1;
    display: flex;
    align-items: center;
    color: var(--hot);
}

.Button.Left {
    display: flex;
}

.Button.Right {
    justify-content: flex-end;
}
