#Page {
    height: calc(100vh - 80px);
    background: #303030;
    color: #f1f1f1;
    font-size: 1.1rem;
    font-weight: 300;
    text-align: center;
    font-family: "Roboto", sans-serif;
    padding: 0.5em;
}

#Page-Center {
    max-width: 500px;
    margin: 0 auto;
}

*:not(input) {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

a {
    text-decoration: none !important;
    color: inherit !important;
}

input {
    outline: none;
}

:root {
    --warm: #d2924d;
    --hot: #fd392b;
    --dark: #242424;
    --light: #303030;
    --rounded: 0.25rem;
    --p3: 1rem;
    --p2: 0.5rem;
}

.text-warm {
    color: var(--warm) !important;
}

.bg-warm {
    background-color: var(--warm) !important;
}

.border-warm {
    border-color: var(--warm) !important;
}

.text-hot {
    color: var(--hot) !important;
}

.border-hot {
    border-color: var(--hot) !important;
}

.bg-hot {
    background-color: var(--hot) !important;
}

.cursor-pointer {
    cursor: pointer !important;
}
